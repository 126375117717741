import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../styles/NavMobile.scss";
import { useTranslation } from "react-i18next";
import Horizonlogo from "../../img/loogo.png";

const NavMobile = () => {
  const [header, setHeader] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("");
    } else if (window.scrollY > 70) {
      return setHeader("alt-color");
    }
  };

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang)
  }
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  useEffect(() => {
    setShowMenu(false);
  }, [location])
  return (
    <div className={`NavMobile ${header} ${showMenu ? "menu-active" : ""}`}>
      <div className="menu-top">
        <div className="hamburger-menu">
          <i
            onClick={() => setShowMenu(!showMenu)}
            className={`fas ${showMenu ? "fa-times" : "fa-bars"}`}
          ></i>
        </div>
        <div className="brand">
          <Link to="/" onClick={() => setShowMenu(false)} style={{display: 'flex', alignItems: "center"}}>
          <img alt="horizon logo" src={Horizonlogo} style={{height: "50px"}}/>
            <h1 className="alt-font">HOTEL HORIZON</h1>
          </Link>
        </div>
        <Link to="/rooms">
          <button className="btn">{t("header.booking")}</button>
        </Link>
      </div>
      <div className={`menu-bottom ${showMenu && "show-menu"}`}>
        <ul>
          <li>
            <div className="contact">
              <i className="fas fa-phone-alt"></i>
              <i className="fas fa-envelope"></i>
              <i className="fab fa-instagram"></i>
              <i className="fab fa-facebook-square"></i>
            </div>
          </li>
          <li onClick={()=>navigate("/rooms")}>
            <Link onClick={() => setShowMenu(false)} to="/rooms">
              {t("header.links.room")}
            </Link>
            <i className="fas fa-chevron-right"></i>
          </li>
          <li onClick={()=>navigate("/dining")}>
            <Link onClick={() => setShowMenu(false)} to="/dining">
            {t("header.links.dining")}
            </Link>
            <i className="fas fa-chevron-right"></i>
          </li>
          <li onClick={()=>navigate("/tours")}>
            <Link onClick={() => setShowMenu(false)} to="/tours">
            {t("header.links.tours")}
            </Link>
            <i className="fas fa-chevron-right"></i>
          </li>
          <li onClick={()=>navigate("/events")}>
            <Link onClick={() => setShowMenu(false)} to="/events">
            {t("header.links.event")}
            </Link>
            <i className="fas fa-chevron-right"></i>
          </li>
          
          <li onClick={()=>navigate("/gallery")}>
            <Link onClick={() => setShowMenu(false)} to="/gallery">
            {t("header.links.gallery")}
            </Link>
            <i className="fas fa-chevron-right"></i>
          </li>
          <li onClick={()=>navigate("/about")}>
            <Link onClick={() => setShowMenu(false)} to="/about">
            {t("header.links.about")}
            </Link>
            <i className="fas fa-chevron-right"></i>
          </li>
          <li onClick={()=>navigate("/login")}>
          <Link to="/login">
                <i className="fas fa-user" style={{margin: "10px"}}></i>
          </Link>
          </li>
          <li >
            <div>
              <img onClick={() => handleChangeLanguage("fr")} src="flags/FR.svg" title="Francais" className="flag-section" alt="(fr)"/>
              <img onClick={() => handleChangeLanguage("en")} src="flags/US.svg" title="English" className="flag-section" alt="(en)"/>
              
            </div>
            
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavMobile;
